<template>
  <div id="divAboutAppWrapper">
    <VueWrapperNarrow class="wrapper-narrow">
      <img src="@/assets/logos/pmaktif.png" alt="pmaktif" class="pmaktif-logo" />
      <!-- <div class="app-static-content" v-html="content"></div> -->
      <div class="app-static-content">v {{ getVersion }}</div>
      <BrandLogo
        :hasBorder="false"
        :width="150"
        :height="120"
        :image="require('@/assets/logos/philip-morris-logo.png')"
        class="logo"
      />
      <VueText sizeLevel="3" weightLevel="2">PM Aktif bir Philip Morris kuruluşudur.</VueText>
    </VueWrapperNarrow>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
// import StaticContent from '@/services/Api/staticContents';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';
import BrandLogo from '@/components/brand/BrandLogo/BrandLogo.vue';
import { version } from '@/../package.json';

export default {
  name: 'AboutApp',
  components: { VueText, VueWrapperNarrow, BrandLogo },
  data() {
    return {
      content: null,
    };
  },
  // created() {
  //   StaticContent.getStaticContent('UygulamaHakkinda').then(res => {
  //     if (res.data.Data) {
  //       this.content = res.data.Data.content;
  //     }
  //   });
  // },
  computed: {
    getVersion() {
      return version;
    },
  },
};
</script>
<style scoped lang="scss">
.wrapper-narrow {
  padding: palette-space-level('60') 0;
}

.app-static-content {
  padding: palette-space-level('20') 0 palette-space-level('60');
  font-size: palette-font-size-level('4');
  line-height: 1.71;
}
.pmaktif-logo {
  width: 200px;
}
.logo {
  margin-left: -6px;
}
</style>
